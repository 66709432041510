<template>
  <div class="text-center">
    <div class="row no-gutters justify-content-between mb-1">
      <div class="col-sm-auto flex-grow-0">
        <h3 class="mb-0 text-left">PSC Inspection - Deficiency Summary</h3>
      </div>
    </div>
    <div class="row  my-3">
      <div class="col-1 text-left">
        <label for="month" class="col-form-label">Month</label>
      </div>
      <div class="col-4">
        <select name="month" id="month" class="form-control form-control-sm" v-model="params.month">
          <option :value="null">--- ALL PERIOD ---</option>
          <option :value="month.id" v-for="month in months">{{month.name}}</option>
        </select>
      </div>
      <div class="col-1 text-left">
        <label for="month" class="col-form-label">YEAR</label>
      </div>
      <div class="col-4">
        <select name="month" id="month" class="form-control form-control-sm" v-model="params.year">
          <option :value="null">--- ALL YEAR ---</option>
          <option :value="year" v-for="year in years">{{year}}</option>
        </select>
      </div>


      <div class="col-2">
        <button id="committeeReport" @click="resetFilter" class="e-btn e-btn-blue-gray force-white-all">
          <font-awesome-icon class="mr-1" icon="filter"/>
          Reset Filter
        </button>
      </div>

    </div>
    <div class="row my-3">
      <div class="col-1 text-left">
        <label for="vessel" class="col-form-label">Vessel</label>
      </div>
      <div class="col-4">
        <select name="vessel" id="month" class="form-control form-control-sm" v-model="params.vessel">
          <option :value="null">--- ALL VESSEL ---</option>
          <option :value="vessel.id" v-for="vessel in vesselKeys">{{vessel.name | toUpper}}</option>
        </select>
      </div>

      <div class="col-1 text-left">
        <small  class="col-form-label">PSC Deficiency Code Group</small>
      </div>
      <div class="col-4">
        <select name="vessel" id="month" class="form-control form-control-sm" v-model="params.psc_code_group" :disabled="params.with_deficiency == 0 || params.psc_code != null">
          <option :value="null">--- ALL DEFICIENCY CODE GROUP ---</option>
          <option :value="node.id" v-for="(node,index) in pscCodeKeys">({{node.code}}) {{ node.description }}</option>
        </select>
      </div>


      <div class="col-2">
        <div class="float-right text-right ml-2 dropdown dropright">
          <button id="committeeReport" aria-expanded="false" aria-haspopup="true" v-show="committeeReport.length" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                  data-toggle="dropdown">
            <font-awesome-icon class="mr-1" icon="download"/>
            Download Committee Report
          </button>
          <div aria-labelledby="committeeReport" class="dropdown-menu">
            <button class="dropdown-item text-secondary font-weight-bolder" @click="downloadReport('excel')" type="button">
              <font-awesome-icon icon="file-excel"/>
              Excel
            </button>
            <button class="dropdown-item text-secondary font-weight-bolder" @click.prevent="downloadReport('pdf')" type="button">
              <font-awesome-icon icon="file-pdf"/>
              PDF
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-1 text-left">
        <label for="month" class="col-form-label">With or Without Deficiency</label>
      </div>
      <div class="col-4">
        <select name="month" id="month" class="form-control form-control-sm" v-model="params.with_deficiency">
          <option :value="null">--- ALL ---</option>
          <option :value="1">WITH DEFICIENCY</option>
          <option :value="0">WITHOUT DEFICIENCY</option>
        </select>
      </div>
      <div class="col-1 text-left">
        <small  class="col-form-label">PSC Deficiency Code</small>
      </div>
      <div class="col-4">
        <button class="custom-button btn border btn-block text-left btn-sm"
                data-target="#pscCodeNew"
                data-toggle="modal"
                style="padding-top: .35rem; padding-bottom: .35rem;"
                :disabled="params.with_deficiency == 0 || params.psc_code_group != null"
        >
           <span style="font-size: 14px;">
              {{this.code.hasOwnProperty('id') ? '(' + this.code.code.toUpperCase() + ') ' +  this.code.description.toUpperCase() : '--- ALL DEFICIENCY CODE ---' }}
           </span>
          <span style="float: right">
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
        </button>
      </div>
    </div>


    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-sm-auto pb-2 mr-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="row no-gutters" style="height: 75vh; overflow-y: auto">
      <div class="col-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" scope="col">#</th>
            <th class="align-middle" scope="col">VESSEL</th>
            <th class="align-middle" scope="col">MANAGER</th>
            <th class="align-middle" style="min-width: 25em" scope="col">REPORT TITLE</th>
            <th class="align-middle" style="width: 1em; font-size: 11px;" scope="col">PSC REPORT</th>
            <th class="align-middle" style="width: 5em" scope="col">STATUS</th>
            <th class="align-middle" style="width: 10em" scope="col">PORT</th>
            <th class="align-middle" style="width: 7em" scope="col">COUNTRY</th>
            <th class="align-middle" style="min-width: 9em" scope="col">INSPECTION DATE</th>
            <th class="align-middle" style="width: 12em" scope="col">RESPONSIBLE</th>
            <th class="align-middle" style="width: 12em" scope="col">PERSON IN CHARGE</th>
            <th class="align-middle" style="width: 12em" scope="col">DEFICIENCY CODE</th>
            <th class="align-middle" style="width: 12em" scope="col">DEFICIENCY DESCRIPTION</th>
            <th class="align-middle" style="width: 12em" scope="col">ROOT CAUSE</th>
            <th class="align-middle" style="width: 12em" scope="col">PREVENTIVE MEASURE</th>
            <th class="align-middle" style="width: 12em" scope="col">ACTION CODE</th>
          </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="100%">
                <app-loading></app-loading>
              </td>
            </tr>
            <template v-else>
              <template v-if="committeeReport">
                <template v-for="(report,index) in committeeReport" >
                  <template v-if="!!report.deficiencies">
                    <tr :key="index">
                      <td :rowspan="report.deficiencies.length + 1" scope="row">{{ index + pagination.from }}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.vessel | toUpper}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.managementOffice | toUpper}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.title | toUpper}}</td>
                      <td :rowspan="report.deficiencies.length + 1">
                        <template v-if="report.pscAttachment">
                          <font-awesome-icon icon="file-pdf" v-if="report.pscAttachment.has_file" @click="viewDownloadFile(report.pscAttachment,1,'view',report.id)" size="3x"
                                             class="force-danger-all" style="cursor: pointer">
                          </font-awesome-icon>
                          <font-awesome-icon icon="file-pdf" v-else  size="3x"></font-awesome-icon>
                        </template>
                      </td>
                      <td :rowspan="report.deficiencies.length + 1" :class="['bg-'+report.report_status_color]">{{report.report_status | toUpper}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.port | toUpper}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.country | toUpper}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.inspectionDate}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.responsiblePerson}}</td>
                      <td :rowspan="report.deficiencies.length + 1">{{report.personInCharge}}</td>
                      <td colspan="5" v-if="!report.deficiencies.length" style="color:#007bff;">No Deficiency</td>
                    </tr>
                    <template v-if="report.deficiencies.length">
                      <tr v-for="deficiency in report.deficiencies">
                        <td>{{deficiency.code}}</td>
                        <td>{{deficiency.description}}</td>
                        <td>{{deficiency.rootCause}}</td>
                        <td>{{deficiency.preventiveMeasure}}</td>
                        <td>{{deficiency.actionCode}}</td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr :key="index">
                      <td :rowspan="report.deficiencies.length + 1" scope="row">{{ index + pagination.from }}</td>
                      <td>{{report.vessel | toUpper}}</td>
                      <td>{{report.managementOffice | toUpper}}</td>
                      <td>{{report.title | toUpper}}</td>
                      <td>
                        <template v-if="report.pscAttachment">
                          <font-awesome-icon icon="file-pdf" v-if="report.pscAttachment.has_file" @click="viewDownloadFile(report.pscAttachment,1,'view',report.id)" size="3x"
                                             class="force-danger-all" style="cursor: pointer">
                          </font-awesome-icon>
                          <font-awesome-icon icon="file-pdf" v-else  size="3x"></font-awesome-icon>
                        </template>
                      </td>
                      <td :class="['bg-'+report.report_status_color]">{{report.report_status | toUpper}}</td>
                      <td>{{report.port | toUpper}}</td>
                      <td>{{report.country | toUpper}}</td>
                      <td>{{report.inspectionDate}}</td>
                      <td>{{report.responsiblePerson}}</td>
                      <td>{{report.personInCharge}}</td>
                    </tr>
                     <tr>
                        <td colspan="5">No Deficiency</td>

                     </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" data-keyboard="false" data-backdrop="static" id="pscCodeNew" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">SELECT DEFICIENCY CODE</h5>
            <button type="button" class="close" @click="setPage(1)" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times;</span>
            </button>
          </div>

          <div class="modal-body overflow-auto " style="height: 50vh">
            <template v-for="(node,index) in pscCodeKeys">
              <h6 class="h6 text-left text-uppercase font-weight-bold">({{node.code}}) {{ node.description }}</h6>
              <psc-action-code-component  :psc-code="code" :dept="10 + index" :node="node" @setPscCode="setSelectedPscCode">
              </psc-action-code-component>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" @click="setPage(1)">Select</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import _ from "lodash";
import AttachmentMixin from "@/mixins/attachmentMixin";
import PscActionCodeComponent from "@/components/modules/psc/deficiency/PscActionCodeComponent.vue";
import {PSC_REPORT_INDEX} from "@/services/ConstantService";
import {UrlService} from "@/services/UrlService";
import axios from "axios";
import {GlobalService} from "@/services/GlobalService";
export default {
  name: "PscMonthlyCommittee",
  components:{PscActionCodeComponent, AppLoading},
  mixins:[PaginationMixin,AttachmentMixin],
  data(){
    return {
      code:{},
      isLoading:false,
      params:{
        vessel:null,
        page:1,
        psc_code:null,
        perPage:30,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        with_deficiency: 1,
        psc_code_group: null
      },
      months:[
        {
          id:1,
          name:'JANUARY'
        },
        {
          id:2,
          name:'FEBRUARY'
        },
        {
          id:3,
          name:'MARCH'
        },
        {
          id:4,
          name:'APRIL'
        },
        {
          id:5,
          name:'MAY'
        },
        {
          id:6,
          name:'JUNE'
        },
        {
          id:7,
          name:'JULY'
        },
        {
          id:8,
          name:'AUGUST'
        },
        {
          id:9,
          name:'SEPTEMBER'
        },
        {
          id:10,
          name:'OCTOBER'
        },
        {
          id:11,
          name:'NOVEMBER'
        },
        {
          id:12,
          name:'DECEMBER'
        },
      ],
      years:Array.from({length:100},(_,i)=>new Date().getFullYear() - i)
    }
  },
  computed:{
    ...mapGetters(['committeeReport','vesselKeys','pscCodeKeys'])
  },
  methods:{
    ...mapActions(['getCommitteeReports','getVesselKeys','getPscCodes']),
    async setPage(page = null){
      delete this.params.type
      this.isLoading=true;
      this.params.page = page ?? 1;
      await this.getCommitteeReports(this.params);
      this.isLoading=false;
    },
    setSelectedPscCode(value){
      this.code=value;
      this.params.psc_code=value.id

    },
    async resetFilter() {
      this.params=Object.assign({},{
        vessel:null,
        page:1,
        psc_code:null,
        perPage:30,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        with_deficiency: 1,
        psc_code_group: null
      })
      this.code={}
      await this.getCommitteeReports(this.params);
    },
    async downloadReport(fileType){
      const params=this.params
      params[fileType]=1;
      fileType === 'excel' ? delete params.pdf : delete params.excel;

      const uri = [PSC_REPORT_INDEX, 'committee'].join('/')
      const queryString= UrlService.setQueryString(params)
      swal.fire({
        'title': `GENERATING DEFICIENCY SUMMARY REPORT <br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      await axios({
        url:uri + queryString,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          swal.close()
          let mime_type = fileType==='pdf' ? 'application/pdf' : 'application/vnd.ms-excel'
          let url = window.URL.createObjectURL(new Blob([response.data],{type:mime_type}))

          fileType = (fileType !== 'pdf') ? 'xlsx' : 'pdf';

          let filename=`PSC_MONTHLY_COMMITTEE_REPORT_${Date.now()}.${fileType}`
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          GlobalService.handleError(error, 'DOWNLOAD PSC DEFICIENCY SUMMARY SERVICE')
          return false
        }).finally(() => {
          delete this.params.pdf;
          delete this.params.excel;
        });
    }
  },
  created() {
    this.getCommitteeReport=_.debounce(this.setPage,750);
    this.getVesselKeys();
    this.getPscCodes()
    this.setPage();
  },

  watch:{
    "params.year"(){
      this.getCommitteeReport();
    },
    "params.month"(){
      this.getCommitteeReport();
    },
    "params.vessel"(){
      this.getCommitteeReport();
    },
    "params.with_deficiency"(){
      this.code = {}
      this.params.psc_code_group = null
      this.params.psc_code = null
      this.getCommitteeReport();
    },
    "params.psc_code_group"(){
      this.getCommitteeReport();
    }
  }


}
</script>

<style scoped>
.custom-button:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
</style>
